@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;



/* BRANDING */
.brand-bg-color {
  @apply bg-blue-500
}

.brand-text-color-white {
  @apply text-white
}

.brand-text-color-white-hover {
  @apply hover:text-white
}

.brand-text-color-100 {
  @apply text-blue-100
}

.brand-text-color-100-hover {
  @apply hover:text-blue-100
}

.brand-text-color-200 {
  @apply text-blue-200
}

.brand-text-color-200-hover {
  @apply hover:text-blue-200
}

.brand-text-color-400 {
  @apply text-blue-400
}

.brand-text-color-400-hover {
  @apply hover:text-blue-400
}

.brand-text-color-500 {
  @apply text-blue-500
}

.brand-text-color-500-hover {
  @apply hover:text-blue-500
}

.brand-text-color-600 {
  @apply text-blue-600
}

.brand-text-color-600-hover {
  @apply hover:text-blue-600
}

.brand-text-color-600-dark {
  @apply dark:text-blue-600
}



.brand-text-color-700 {
  @apply text-blue-700
}

.brand-text-color-700-hover {
  @apply hover:text-blue-700
}



.brand-text-color-800-group-hover {
  @apply group-hover:text-blue-800
}

.brand-text-color-800 {
  @apply text-blue-800
}

.brand-text-color-800-hover {
  @apply hover:text-blue-800
}

.brand-text-color-900 {
  @apply text-blue-900
}

.brand-text-color-900-hover {
  @apply hover:text-blue-900
}

.brand-border-color-200 {
  @apply border-blue-200
}
.brand-border-color-200-hover {
  @apply hover:border-blue-200
}

.brand-border-color-300 {
  @apply border-blue-300
}
.brand-border-color-300-hover {
  @apply hover:border-blue-300
}

.brand-border-color-500 {
  @apply border-blue-500
}
.brand-border-color-500-hover {
  @apply hover:border-blue-500
}

.brand-fill-color-300-dark {
  @apply dark:fill-blue-300
}

.brand-fill-color-600 {
  @apply fill-blue-600 
}

.brand-sidebar-bg-color-900 {
  @apply bg-blue-900
}

.brand-sidebar-bg-color-700 {
  @apply bg-blue-800
}

.brand-bg-color-100 {
  @apply bg-blue-100
}

.brand-bg-color-200 {
  @apply bg-blue-200
}

.brand-bg-color-700 {
  @apply bg-blue-700
}

.brand-btn-color {
  @apply bg-blue-600
}

.brand-btn-color-hover {
  @apply hover:bg-blue-700
}

.brand-btn-color-text {
  @apply text-white
}

.brand-odd-color-dark-100 {
  @apply odd:dark:bg-blue-100
}

.brand-even-color-dark-200 {
  @apply even:dark:bg-blue-200
}


/* END BRAND */

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}
